import * as React from 'react';
import * as yup from 'yup';

export const useValidEmail = (initialValue: string) => {
    const [email, setEmail] = React.useState(initialValue);
    const [emailIsValid, setEmailIsValid] = React.useState(true);

    React.useEffect(() => {
        const emailSchema = yup.object().shape({
            email: yup.string().email().required(),
        });

        if (email.length === 0) {
            setEmailIsValid(true);
            return;
        }

        const isValid = emailSchema.isValidSync({ email });

        setEmailIsValid(isValid);
    }, [email]);

    return { email, setEmail, emailIsValid };
};

export const useValidPassword = (initialValue: string, validateStrength = false) => {
    const [password, setPassword] = React.useState(initialValue);
    const [passwordIsValid, setPasswordIsValid] = React.useState(true);

    React.useEffect(() => {
        const passwordSchema = yup.object().shape({
            password: yup.string().min(10).required(),
        });

        const passwordStrengthSchema = yup.object().shape({
            password: yup
                .string()
                .min(10)
                .matches(
                    /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
                )
                .required(),
        });

        if (password.length === 0) {
            setPasswordIsValid(true);
            return;
        }

        const isValid = validateStrength
            ? passwordStrengthSchema.isValidSync({ password })
            : passwordSchema.isValidSync({ password });

        setPasswordIsValid(isValid);
    }, [password, validateStrength]);

    return { password, setPassword, passwordIsValid };
};

export const useValidUsername = (initialValue: string) => {
    const [username, setUsername] = React.useState(initialValue);
    const [usernameIsValid, setUsernameIsValid] = React.useState(true);

    React.useEffect(() => {
        const usernameSchema = yup.object().shape({
            username: yup.string().email().required(),
        });

        if (username.length === 0) {
            setUsernameIsValid(true);
            return;
        }

        const isValid = usernameSchema.isValidSync({ username });

        setUsernameIsValid(isValid);
    }, [username]);

    return { username, setUsername, usernameIsValid };
};

export const useValidCode = (initialValue: string) => {
    const [code, setCode] = React.useState(initialValue);
    const [codeIsValid, setCodeIsValid] = React.useState(true);

    React.useEffect(() => {
        const codeSchema = yup.object().shape({
            code: yup.string().min(6).required(),
        });

        if (code.length === 0) {
            setCodeIsValid(true);
            return;
        }

        const isValid = codeSchema.isValidSync({ code });

        setCodeIsValid(isValid);
    }, [code]);

    return { code, setCode, codeIsValid };
};

export const useRequiredField = (initialValue: string) => {
    const [field, setField] = React.useState(initialValue);
    const [isValid, setIsValid] = React.useState(true);

    React.useEffect(() => {
        const schema = yup.object().shape({
            field: yup.string().required(),
        });

        if (field.length === 0) {
            setIsValid(true);
            return;
        }

        const isValid = schema.isValidSync({ field });

        setIsValid(isValid);
    }, [field, setField, isValid]);

    return { field, setField, isValid };
};
