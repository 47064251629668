import { toaster } from '@/components/ui/toaster';

export default function useCustomToast() {
    const showCustomToast = ({
        title,
        description,
        type = 'info',
        status,
        duration = 5000,
        ...props
    }: {
        title: string;
        description?: string;
        type?: 'info' | 'success' | 'error' | 'warning';
        // @deprecated use the type property
        status?: 'info' | 'success' | 'error' | 'warning';
        duration?: number;
        [key: string]: any;
    }) => {
        toaster.create({
            title,
            description,
            type: status ?? type,
            duration,
            ...props,
        });
    };

    return showCustomToast;
}
